.ant-form {
  width: 80%;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.radio-button-create-notification-form, .radio-button-create-notification-form > label{
  height: 32px;
  font-size: 14px;
  line-height: 32px;
}

.radio-button-create-notification-form > label{
  width: 100px;
}

.radio-button-create-notification-form.radio-group-message-type > label{
  width: 120px;
}

.radio-button-create-notification-form.radio-group-notification-type > label{
  width: 200px;
}



.form-display-inline .ant-col.ant-form-item-control-wrapper{
  display: inline-block;
}