.ant-descriptions {
  max-width: 600px;
}


.radio-button-receipt-sender > label{
  height: 32px;
  width: 80px;
  font-size: 14px;
  line-height: 32px;
}