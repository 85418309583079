.ant-form {
  width: 100%;
}

.controller-form {
  margin: 0.2% 0;
  padding: 0.5% 0;
  &__controller {
    display: block;
    block-size: 100%;
    grid-template-columns: 100% 20%;
    grid-template-rows: 100%;
    .controller {
      margin-right: 10%;
      display: inline-flex;
      &__title {
        margin: 1%;
      }
      &__remove-btn{
        margin-left: 5%;
        margin-top: 2%;
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
