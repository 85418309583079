.ant-form {
  width: 100%;
}

.uv_control_schedule .controller-form {
  margin: 0.2% 0;
  padding: 0.5% 0;
  &__controller {
    display: block;
    block-size: 100%;
    grid-template-columns: 100% 20%;
    grid-template-rows: 100%;
    .controller {
      margin-right: 10%;
      display: inline-flex;
      &__title {
        margin: 1%;
      }
      &__remove-btn{
        margin-left: 5%;
        margin-top: 1%;
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.item-uv-schedule .controller-form__controller .ant-row.ant-form-item{
  margin-bottom: 0px;
}

.item-uv-schedule .controller-form__controller{
  margin-bottom: 10px;
}

.warning-uv-time, .error-uv-time{
  color: #f5222d;
  margin-top: -12px;
  line-height: initial;
}
