.one-line{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}

.notification-state{
      text-align: right;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
    }