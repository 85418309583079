.ant-form {
  width: 80%;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}


.user-toasts-message ul.ant-list-items > li{
  padding: 2px 0;
}

.user-toasts-message ul.ant-list-items > li > div{
  padding: 5px 15px 5px 64px;
  width: 100%;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-error {
  background-color: transparent;
  border-left: 5px solid #f5222d;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-info {
  background-color: transparent;
  border-left: 5px solid #1890ff;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-warning {
  background-color: transparent;
  border-left: 5px solid #faad14;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-success {
  background-color: transparent;
  border-left: 5px solid #52c41a;
}

.radio-button-user-receipt-sender > label{
  height: 32px;
  width: 80px;
  font-size: 14px;
  line-height: 32px;
}