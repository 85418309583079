.ant-descriptions {
  max-width: 600px;
}

.contenedor > div > .panel{
  padding-bottom: 30px;
  overflow: hidden;
}

.title-panel {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 0px !important;
}

.move-title-panel {
  margin-right: 60px;
  height: 100%;
  display: block;
}

.title-panel > .move-title-panel > .text-title-panel {
  padding: 5px;
  display: block;
  width: 100%;
}

.title-panel > .btn-panel.close {
  margin: 5px;
}

.title-panel > .btn-panel.maximizer {
  margin: 5px 0px;
}

.body-panel {
  width: 100%;
  margin-right: 5px;
  height: 100%;
  margin-top: 30px;
  z-index: 0;
  margin-bottom: -30px;
}

.contenedor > div > .panel *::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: rgba(40, 45, 49, 0.07);
  border-radius: 50px;
}
