.ant-layout-header {
  background: gray;
  /*padding-top: 1.5em;
  padding-bottom: 1.5em;*/
  padding-right: 5em;
  padding-left: 1em;
}

.ant-layout-header .ant-col {
  margin-left: 2em;
}

.icon-user-nitification > sup{
  background: #287d8e;
  top: 12px;
  right: 10px;
}

.icon-user-nitification > span > i{
  font-size: 20px;
  border-radius: 50px;
  padding: 7px;
  background: #d9d9d9;
  margin: 14px 5px 5px 5px;
  color: #287e8f !important;
  border: 1px solid #287e8f;
}


.icon-badge{
  position: absolute;
    top: 8px;
    line-height: 14px;
    border: 1px solid white;
    border-radius: 50px;
    padding: 2px 5px;
    color: white;
    background: #287d8e;
    right: 0px;
}


.ant-notification.ant-notification-topLeft{
  width: 80%;
}

.toast-notification-show i.anticon-bell{
  border: 2px solid #52c41a;
  padding: 4px;
  font-size: 14px;
  color: #52c41a;
  border-radius: 50px;
}

.notification-detail .ant-radio-button-wrapper {
    padding: 0 10px;
}