.device{
    margin-left: -5px;
    margin-top: -5px;
}

.exit{
    margin-left: -5px;
    margin-top: -5px;
}

.contenedor{
    width: fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
    width: 500px;
    height: auto;
}

.vertical{
    height: 500px;
    width: auto;
}