.ant-form {
  width: 80%;
}

.data__card{
  background: rgba(240, 220, 106, 0.281);
  box-shadow: 1px 1px 4px 1px rgba(240, 220, 106, 0.2);
  border-radius: 5px;
  border: 5%;
}

.data__card .content-device-tags{
   margin-top: -10px;
   text-align: right;

  }

.data__card .light_capability{
  border-radius: 50px;
  line-height: 17px;
  text-transform: lowercase;
  margin-right: -10px;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}
