/* :root {
  --primary-color-bg: #595959;
  --card-hoverable-color: #5959597c;
  --red-color: #da2e4f;
  --red-color-hover: #da2e50ce;
  --primary-color: #6e5c9a;
  --primary-color-hover: #6e5c9acb;
  --badge-border-color: #6e5c9a;
  --badge-border-coslor: #6e5c9a7c;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.primary__color {
  cursor: pointer;
  transition: background color border tranform 0.3s 0.3s 0.3s 0.1s;
}

.primary__color:hover {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

/** LogIn Page 
.form-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 1.5em;
}

/** Evaluator Page 
.sider-logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.header-styles {
  background-color: #fff;
  box-shadow: 0 0 10px var(--card-hoverable-color);
  z-index: 1;
  padding-left: 1em;
}

.sider-styles {
  box-shadow: 0 0 10px var(--card-hoverable-color);
  border-right-width: 0;
  z-index: 1;
}

.content-styles {
  
  background-color: #fff;
}

.content-layout-styles {
  
}

.navbar-col {
}

.btn-link {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
}

.btn-link:hover {
  text-decoration: underline;
}

.notification-title {
  display: flex;
  align-items: center;
  justify-content: start;
}

.ant-layout-sider-trigger:hover .anticon-right,
.ant-layout-sider-trigger:hover .anticon-left {
  color: var(--primary-color);
}
.ant-layout-sider-trigger:active .anticon-right,
.ant-layout-sider-trigger:active .anticon-left,
.primary__color:active {
  transform: scale(0.9);
}

.ant-menu-vertical {
  border-right: 0;
}

.card__click:active {
  transform: scale(0.99);
}

.ant-list-item-meta {
  align-items: center;
}

.ant-pagination {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
}

.ant-card {
  overflow: hidden;
}

.ant-card-actions {
  transform: translateY(298px);
  transition: all 0.3s;
}

.ant-card:hover .ant-card-actions {
  transform: translateY(0);
}

.badge-hoverable {
  transition: all 0.3s;
}

.card-hoverable:hover,
.badge-hoverable:hover {
  box-shadow: 0 4px 12px var(--card-hoverable-color);
  cursor: pointer;
  transform: translateY(-8px);
  padding-bottom: 0;
}

.badge-hoverable:hover .ant-card {
  margin-bottom: 0;
}

.card-hoverable:hover .ant-avatar {
  background-color: var(--primary-color-hover);
}

.ant-card-grid {
  height: 15em;
  overflow: hidden;
}

.row-flex-padding {
  padding: 3em;
}

.flex-margin {
  margin: 3em;
}

.card__active {
  box-shadow: 0 0 3px var(--primary-color);
}
.card__active .ant-avatar {
  background-color: var(--primary-color);
}

.footer {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.editable_row {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 90%;
}

.editable {
  display: inline-block;
  border-bottom: 1px solid transparent;
  cursor: text;
  padding-right: 1vw;
  min-width: 5vw;
  min-height: 20px;
  margin-bottom: 0;

  transition: all 0.3s;
}

.editable:hover {
  border-bottom: 1px solid var(--primary-color);
}

.editing {
  border-bottom: 1px solid var(--primary-color);
}

.editing:focus {
  outline: none;
}

.ant-btn-danger {
  color: #fff;
  background-color: var(--red-color);
  border: none;
}

.ant-btn-danger:hover {
  color: #fff;
  background-color: var(--red-color-hover);
  border: none;
}

.ant-badge-count {
  background-color: var(--red-color);
}

.box-col {
  padding: 1em;
  box-shadow: 0 0 10px #d9d9d9;
}

.ant-table-row:hover .ant-avatar {
  background-color: var(--primary-color-hover);
}

.ant-avatar {
  transition: 0.3s all;
}

.ant-form-item-control {
  line-height: 1.5;
}

/** SingUp Page 
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.back-login {
  position: absolute;
  top: 8vh;
  font-size: 16px;
}
.back-login svg {
  transition: transform 0.1s ease-in;
}
.back-login:hover svg {
  transform: translateX(-0.3em);
}

.steps-action {
  text-align: center;
}
.steps-content {
  padding-top: 0;
  text-align: left;
} 
*/

:root {
  --primary-color: #287d8e;
  --primary-color-hover: #1d5a66;
  --element-color-hover: #5959590e;
  --element-border-radius: 4px;
  /* --dark-icon-color: #595959; 
     --card-hoverable-color: #5959597c; */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.ant-avatar {
  background-color: #287d8e;
  background-color: var(--primary-color);
}

/*? Estilos al scroll del navegador cuando es chrome */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  z-index: 10;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #287d8e;
  background-color: var(--primary-color);
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #1d5a66;
  background: var(--primary-color-hover);
}
*::-webkit-scrollbar-thumb:active {
  background: #1d5a66;
  background: var(--primary-color-hover);
}

/*? Estilos al scroll cuando es firefox */
/* body {
  overflow-y: scroll;
  scrollbar-color: var(--primary-color) transparent;
  scrollbar-width: thin;
} */

.icon-animated {
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-animated:active .anticon {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.centered-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
}

.centered-subtitle {
  text-align: center;
  margin-bottom: 2.5em;
}

.centered-img {
  object-fit: cover;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  width: 100% !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #287d8e;
  border-radius: 50%;
}

.circle.animar {
  -webkit-animation: anim 1.8s linear infinite;
          animation: anim 1.8s linear infinite;
}

.circle.animar:nth-child(2) {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}

.circle.animar:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

@-webkit-keyframes anim {
  0% {
    -webkit-transform-origin: 200% 30%;
            transform-origin: 200% 30%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 200% 30%;
            transform-origin: 200% 30%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  50.1% {
    -webkit-transform-origin: -100% 30%;
            transform-origin: -100% 30%;
    -webkit-transform: rotate(360);
            transform: rotate(360);
  }
  100% {
    -webkit-transform-origin: -100% 30%;
            transform-origin: -100% 30%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes anim {
  0% {
    -webkit-transform-origin: 200% 30%;
            transform-origin: 200% 30%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 200% 30%;
            transform-origin: 200% 30%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  50.1% {
    -webkit-transform-origin: -100% 30%;
            transform-origin: -100% 30%;
    -webkit-transform: rotate(360);
            transform: rotate(360);
  }
  100% {
    -webkit-transform-origin: -100% 30%;
            transform-origin: -100% 30%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}



.centered-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
}

.centered-subtitle {
  text-align: center;
  margin-bottom: 2.5em;
}

.centered-img {
  object-fit: cover;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  width: 100% !important;
}

.ant-layout-sider {
  /*border-right: 1px solid #e8e8e8;*/
  background-color: #0E132B;
  z-index: 1;
}

.ant-menu {
  background-color: #0E132B;
}

.ant-layout-sider-trigger {
  /*border-right: 1px solid #e8e8e8;*/
}

.ant-layout-content {
  /*background-color: rgb(240, 240, 240);*/
}

.logo {
  height: 80px;
  background: url('/logo.png');
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: contain;
  background-position-x: center;
  margin: 16px;
}

.ant-row-flex {
  margin: 0 2em;
}

.ant-form {
  /*width: 80%;*/
}

.ant-layout-header {
  background: gray;
  /*padding-top: 1.5em;
  padding-bottom: 1.5em;*/
  padding-right: 5em;
  padding-left: 1em;
}

.ant-layout-header .ant-col {
  margin-left: 2em;
}

.icon-user-nitification > sup{
  background: #287d8e;
  top: 12px;
  right: 10px;
}

.icon-user-nitification > span > i{
  font-size: 20px;
  border-radius: 50px;
  padding: 7px;
  background: #d9d9d9;
  margin: 14px 5px 5px 5px;
  color: #287e8f !important;
  border: 1px solid #287e8f;
}


.icon-badge{
  position: absolute;
    top: 8px;
    line-height: 14px;
    border: 1px solid white;
    border-radius: 50px;
    padding: 2px 5px;
    color: white;
    background: #287d8e;
    right: 0px;
}


.ant-notification.ant-notification-topLeft{
  width: 80%;
}

.toast-notification-show i.anticon-bell{
  border: 2px solid #52c41a;
  padding: 4px;
  font-size: 14px;
  color: #52c41a;
  border-radius: 50px;
}

.notification-detail .ant-radio-button-wrapper {
    padding: 0 10px;
}
.ant-form {
  width: 80%;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}


.user-toasts-message ul.ant-list-items > li{
  padding: 2px 0;
}

.user-toasts-message ul.ant-list-items > li > div{
  padding: 5px 15px 5px 64px;
  width: 100%;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-error {
  background-color: transparent;
  border-left: 5px solid #f5222d;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-info {
  background-color: transparent;
  border-left: 5px solid #1890ff;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-warning {
  background-color: transparent;
  border-left: 5px solid #faad14;
}

.user-toasts-message ul.ant-list-items > li > div.ant-alert-success {
  background-color: transparent;
  border-left: 5px solid #52c41a;
}

.radio-button-user-receipt-sender > label{
  height: 32px;
  width: 80px;
  font-size: 14px;
  line-height: 32px;
}
.one-line{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}

.notification-state{
      text-align: right;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
    }
.ant-form {
  width: 80%;
}


.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.toasts-message ul.ant-list-items > li{
  padding: 2px 0;
}

.toasts-message ul.ant-list-items > li > div{
  padding: 5px 15px 5px 64px;
  width: 100%;
}


.toasts-message ul.ant-list-items > li > div.ant-alert-error {
  background-color: transparent;
  border-left: 5px solid #f5222d;
}

.toasts-message ul.ant-list-items > li > div.ant-alert-info {
  background-color: transparent;
  border-left: 5px solid #1890ff;
}

.toasts-message ul.ant-list-items > li > div.ant-alert-warning {
  background-color: transparent;
  border-left: 5px solid #faad14;
}

.toasts-message ul.ant-list-items > li > div.ant-alert-success {
  background-color: transparent;
  border-left: 5px solid #52c41a;
}

.toasts-message ul.ant-list-items > li i.ant-alert-icon {
  top: 6px;
}

.toast-data .toasts-message i.anticon-bell{
  font-size: 16px;
    border: 2px solid #52c41a;
    border-radius: 50px;
    padding: 3px;
}
.ant-form {
  width: 80%;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.notification-detail-info{
  padding: 5px 10px;
}

.notification-detail-info.align-left{
  text-align: right;
}

.notification-detail-info.align-left > span.date{
  width: 120px;
  display: inline-block;
}

.notification-detail-info.detail > div{
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

.notification-detail-info.detail > div .subject{
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid gray;
}

.notification-detail-info.detail .ant-alert-with-description {
  padding-bottom: 0px;
}

.notification-detail-info.user-receivers{
  font-weight: 600;
  border-bottom: 1px solid gray;
  margin-top: 10px;
}

.ant-form {
  width: 80%;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.radio-button-create-notification-form, .radio-button-create-notification-form > label{
  height: 32px;
  font-size: 14px;
  line-height: 32px;
}

.radio-button-create-notification-form > label{
  width: 100px;
}

.radio-button-create-notification-form.radio-group-message-type > label{
  width: 120px;
}

.radio-button-create-notification-form.radio-group-notification-type > label{
  width: 200px;
}



.form-display-inline .ant-col.ant-form-item-control-wrapper{
  display: inline-block;
}
.ant-form {
  /*width: 80%;*/
}

.ant-descriptions {
  max-width: 600px;
}

.frecuencycontrol .slider-hour .ant-slider-rail, .ant-slider-track {
    position: absolute;
    height: 4px !important;
    background-color: #aaa !important; 
}

.frecuencycontrol .slider-hour .ant-slider-step .ant-slider-dot{
    border-color: #287d8e !important;
    background-color:  #287d8e !important;
}
.frecuencycontrol .slider-hour .step1 .ant-slider-step .ant-slider-dot:nth-child(1),
.frecuencycontrol .slider-hour .step2 .ant-slider-step .ant-slider-dot:nth-child(2),
.frecuencycontrol .slider-hour .step3 .ant-slider-step .ant-slider-dot:nth-child(3),
.frecuencycontrol .slider-hour .step4 .ant-slider-step .ant-slider-dot:nth-child(4),
.frecuencycontrol .slider-hour .step5 .ant-slider-step .ant-slider-dot:nth-child(5),
.frecuencycontrol .slider-hour .step6 .ant-slider-step .ant-slider-dot:nth-child(6),
.frecuencycontrol .slider-hour .step7 .ant-slider-step .ant-slider-dot:nth-child(7),
.frecuencycontrol .slider-hour .step8 .ant-slider-step .ant-slider-dot:nth-child(8),
.frecuencycontrol .slider-hour .step9 .ant-slider-step .ant-slider-dot:nth-child(9),
.frecuencycontrol .slider-hour .step10 .ant-slider-step .ant-slider-dot:nth-child(10),
.frecuencycontrol .slider-hour .step11 .ant-slider-step .ant-slider-dot:nth-child(11),
.frecuencycontrol .slider-hour .step12 .ant-slider-step .ant-slider-dot:nth-child(12),
.frecuencycontrol .slider-hour .step13 .ant-slider-step .ant-slider-dot:nth-child(13),
.frecuencycontrol .slider-hour .step14 .ant-slider-step .ant-slider-dot:nth-child(14),
.frecuencycontrol .slider-hour .step15 .ant-slider-step .ant-slider-dot:nth-child(15),
.frecuencycontrol .slider-hour .step16 .ant-slider-step .ant-slider-dot:nth-child(16),
.frecuencycontrol .slider-hour .step17 .ant-slider-step .ant-slider-dot:nth-child(17),
.frecuencycontrol .slider-hour .step18 .ant-slider-step .ant-slider-dot:nth-child(18),
.frecuencycontrol .slider-hour .step19 .ant-slider-step .ant-slider-dot:nth-child(19),
.frecuencycontrol .slider-hour .step20 .ant-slider-step .ant-slider-dot:nth-child(20),
.frecuencycontrol .slider-hour .step21 .ant-slider-step .ant-slider-dot:nth-child(21),
.frecuencycontrol .slider-hour .step22 .ant-slider-step .ant-slider-dot:nth-child(22),
.frecuencycontrol .slider-hour .step23 .ant-slider-step .ant-slider-dot:nth-child(23),
.frecuencycontrol .slider-hour .step24 .ant-slider-step .ant-slider-dot:nth-child(24){
    border-color: #ff6060  !important;
    background-color:  #ff6060 !important;
}

.frecuencycontrol .slider-hour .ant-slider-mark-text {
    color: 
rgb(40, 45, 49) !important;
}
.device{
    margin-left: -5px;
    margin-top: -5px;
}

.exit{
    margin-left: -5px;
    margin-top: -5px;
}

.contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
    width: 500px;
    height: auto;
}

.vertical{
    height: 500px;
    width: auto;
}
.device{
    width: 40px;
    margin-left: -20px;
    margin-top: -10px;
  }

  .device.inactive::after{
    content: '';
    border-radius: 100px;
    width: 100px;
    height: 100px;
    margin-top: -80px;
    margin-left: -30px;
    display: inherit;
    background: -webkit-radial-gradient( 50% 50%, circle farthest-side, rgb(36, 36, 36), rgba(36, 36, 36, 0) );
	  background: -webkit-radial-gradient( 50% 50%, farthest-side circle, rgb(36, 36, 36), rgba(36, 36, 36, 0) );
	  background: radial-gradient( farthest-side circle at 50% 50%, rgb(36, 36, 36), rgba(36, 36, 36, 0) );
  }

  .contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}


@supports (-ms-ime-align:auto) {
  .contenedor{
    display: table;
  }
}
@media screen and (min-width:0\0){
  .contenedor{
    display: table;
  }
 }

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}

.legend .ant-tooltip-inner{
  width: 500px;
  color: black;
  background-color: rgb(255, 255, 255);
}

.device-name{
  font-size: 12px;
  margin-top: -5px;
  white-space: nowrap;
  color: black;
  font-weight: 600;
  text-shadow: -1px -1px 3px #ffffff, 1px 1px 3px #ffffff;
  display: inline-grid;
  margin-left: -30%;
  line-height: 12px;
  min-width: 40px;
}

.device-name > span:nth-child(2){
  font-size: 10px;
  line-height: 10px;
}

.exit-name.device-name{
  text-transform: uppercase;
  margin-left: 0%;
}

.exit.device{
line-height: 14px;
}


.image-name.text-green{
  color: green;
}
.image-name.text-red{
  color: red;
}
.image-name{
  font-size: 12px;
  white-space: nowrap;
  color: black;
  font-weight: 700;
  text-shadow: -1px -1px 3px #ffffff, 1px 1px 3px #ffffff;
}


.green-button, .green-button.ant-btn:hover, .green-button.ant-btn:focus, .green-button > button{
  background-position: center;
    background-size: cover;
  background: url('/green.png');
}

.tricolor-button, .tricolor-button.ant-btn:hover, .tricolor-button.ant-btn:focus, .tricolor-button > button{
  background-position: center;
    background-size: cover;
  background: url('/tricolor.png');
}

.arrows-button, .arrows-button.ant-btn:hover, .arrows-button.ant-btn:focus, .arrows-button > button{
  background-position: center;
    background-size: cover;
  background: url('/arrows.png');
}

.ant-list.occupancy-data{
  margin-bottom: 10px;
}
.ant-list.occupancy-data .ant-list-header, .compilance-warning .ant-list-header{
  text-align: center;
  padding: 5px 5px;
  font-weight: 600;
}

.ant-list.occupancy-data ul.ant-list-items li{
  text-align: center;
  padding: 5px 14px;
}

.ant-list.occupancy-data .occupancy-data-value{
  color: #287d8e;
  font-size: 16px;
  font-weight: 700;
}

.ant-list.compilance-warning ul.ant-list-items li{
  padding: 5px 2px;
}

.ant-list.compilance-warning li .warning-time{
  font-size: 11px;
  width: 35px;
  position: absolute;
  display: inline-block;
  margin-top: 2px;
}

.ant-list.compilance-warning li .warning-text{
  font-size: 13px;
  margin-left: 38px;
}

.red-text{
  color:rgb(206, 17, 38) !important;
}

.motion-buttons > .ant-btn{
  margin-right: 1px;
  margin-bottom: 1px;
}

.motion-buttons > .ant-btn.selected{
  border-color: #287d8e;
}

.frecuencycontrol .ant-btn-circle{
  width: 32px;
}
.device{
    margin-left: -20px;
    margin-top: -10px;
  }

  .contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}
.ant-descriptions {
  max-width: 600px;
}

.ant-form {
  width: 80%;
}

.data__card{
  background: rgba(240, 220, 106, 0.281);
  box-shadow: 1px 1px 4px 1px rgba(240, 220, 106, 0.2);
  border-radius: 5px;
  border: 5%;
}

.data__card .content-device-tags{
   margin-top: -10px;
   text-align: right;

  }

.data__card .light_capability{
  border-radius: 50px;
  line-height: 17px;
  text-transform: lowercase;
  margin-right: -10px;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.ant-form {
  width: 80%;
}

.ant-radio-button-wrapper{
  width: 70px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
}

.ant-radio-button-wrapper.middle{
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
  color: #287e8f;
  background: #fff;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled).radio-button-overriden {
  color: #ff4d4f;
  border-color: #fab9ba;
  background: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  z-index: 1;
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  box-shadow: -1px 0 0 0 #ff4d4f;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden::before {
  background-color: #ff4d4f !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  z-index: 1;
  color: #fff;
  background: #287e8f;
  border-color: #287e8f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:hover:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
}

.bubble-text {
  color: #ff4d4f;
  font-size: 12px;
  text-align: center,
}

.arrow_box {
  position: relative;
  border-radius: .4em;
	background: transparent;
  border: 2px solid #ff4d4f;
  padding-left: 5% !important;
  padding-right: 5% !important;
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}

.arrow_box:after { 
  content: ''; 
  position: absolute; 
  bottom: -40%;
  left: 50%; 
  width: 0; 
  height: 0; 
  border: 6px solid transparent; 
  border-top-color: #ff4d4f; 
  border-bottom: 0; 
  margin-left: -6px; 
  margin-top: -6px;
}

@-moz-document url-prefix(){
  .arrow_box{
    border: 3px solid #ff4d4f;
  }
}

.inline-div{
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}

.inline-div-center{
  -webkit-transform: translateY(-45px);
          transform: translateY(-45px);
}

.ant-form {
  width: 80%;
}

.ant-form {
  width: 80%;
}

.ant-form {
  width: 100%; }

.uv_control_schedule .controller-form {
  margin: 0.2% 0;
  padding: 0.5% 0; }
  .uv_control_schedule .controller-form__controller {
    display: block;
    block-size: 100%;
    grid-template-columns: 100% 20%;
    grid-template-rows: 100%; }
    .uv_control_schedule .controller-form__controller .controller {
      margin-right: 10%;
      display: inline-flex; }
      .uv_control_schedule .controller-form__controller .controller__title {
        margin: 1%; }
      .uv_control_schedule .controller-form__controller .controller__remove-btn {
        margin-left: 5%;
        margin-top: 1%;
        display: inline-flex;
        justify-content: center;
        cursor: pointer; }

.item-uv-schedule .controller-form__controller .ant-row.ant-form-item {
  margin-bottom: 0px; }

.item-uv-schedule .controller-form__controller {
  margin-bottom: 10px; }

.warning-uv-time, .error-uv-time {
  color: #f5222d;
  margin-top: -12px;
  line-height: normal;
  line-height: initial; }

.ant-form {
  width: 80%;
}

.ant-radio-button-wrapper{
  width: 70px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
}

.ant-radio-button-wrapper.middle{
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.advanced-schedule .ant-form-item-control{
  line-height: 1.5;
}

.advanced-schedule .ant-checkbox-group > label{
  width: 100%;
  margin-top: 5px;
}

.advanced-schedule .advanced-schedule-tab > .ant-tabs-content > div{
  padding: 0px 40px;
}
.advanced-schedule .advanced-schedule-tab .schedule-form{
  margin-top: 20px;
}

.slider-select-period{
  padding: 0px 40px;
}
.ant-descriptions {
  max-width: 600px;
}

.device{
    margin-left: -20px;
    margin-top: -10px;
  }

  .contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}
.ant-descriptions {
  max-width: 600px;
}

.device{
    margin-left: -20px;
    margin-top: -10px;
  }

  .contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}
.ant-form {
  width: 80%;
}

.ant-radio-button-wrapper{
  width: 70px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
}

.ant-radio-button-wrapper.middle{
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
  color: #287e8f;
  background: #fff;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled).radio-button-overriden {
  color: #ff4d4f;
  border-color: #fab9ba;
  background: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  z-index: 1;
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  box-shadow: -1px 0 0 0 #ff4d4f;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden::before {
  background-color: #ff4d4f !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  z-index: 1;
  color: #fff;
  background: #287e8f;
  border-color: #287e8f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:hover:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
}

.bubble-text {
  color: #ff4d4f;
  font-size: 12px;
  text-align: center,
}

.arrow_box {
  position: relative;
  border-radius: .4em;
	background: transparent;
  border: 2px solid #ff4d4f;
  padding-left: 5% !important;
  padding-right: 5% !important;
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}

.arrow_box:after { 
  content: ''; 
  position: absolute; 
  bottom: -40%;
  left: 50%; 
  width: 0; 
  height: 0; 
  border: 6px solid transparent; 
  border-top-color: #ff4d4f; 
  border-bottom: 0; 
  margin-left: -6px; 
  margin-top: -6px;
}

@-moz-document url-prefix(){
  .arrow_box{
    border: 3px solid #ff4d4f;
  }
}

.inline-div{
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}

.inline-div-center{
  -webkit-transform: translateY(-45px);
          transform: translateY(-45px);
}

.not-margin .ant-form {
  width: 100%;
}

.not-margin > div {
  margin: 0px;
  padding: 1em;
  border: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

.uv_control_schedule{
 margin-bottom: 50px;
}

.uv_control_schedule > div{
  margin-bottom: 0px;
 }

 .uv_control_schedule > div.controller-form{
  margin-top: 30px;
 }


.uv_control_schedule .controller__title.start, .uv_control_schedule .controller__title.stop{
  width: 128px;
  text-align: center;
}

.uv_control_schedule .controller-form__controller{
  line-height: 0px;
}

.line-bottom-div{
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

.margin-right-20{
  margin-right: 20px !important;
}
                      

.uv_control_schedule .controller__remove-btn .anticon {
  margin: 6px;
}
.ant-descriptions {
  max-width: 600px;
}

.ant-form {
  width: 80%;
}

.peak-cost{
    margin-top: 10px;
    margin-left: 50px;
    padding: 3px;
    border: solid thin;
}
.ant-descriptions {
  max-width: 600px;
}


.radio-button-reciber-sender > label{
  height: 32px;
  font-size: 14px;
  line-height: 32px;
}
.ant-descriptions {
  max-width: 600px;
}


.radio-button-receipt-sender > label{
  height: 32px;
  width: 80px;
  font-size: 14px;
  line-height: 32px;
}
.ant-descriptions {
  max-width: 600px;
}

.device{
    margin-left: -20px;
    margin-top: -10px;
  }

  .contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}
.ant-descriptions {
  max-width: 600px;
}

.ant-form {
  width: 80%;
}

.ant-radio-button-wrapper{
  width: 70px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
}

.ant-radio-button-wrapper.middle{
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
  color: #287e8f;
  background: #fff;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled).radio-button-overriden {
  color: #ff4d4f;
  border-color: #fab9ba;
  background: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  z-index: 1;
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  box-shadow: -1px 0 0 0 #ff4d4f;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden::before {
  background-color: #ff4d4f !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  z-index: 1;
  color: #fff;
  background: #287e8f;
  border-color: #287e8f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:hover:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
}

.bubble-text {
  color: #ff4d4f;
  font-size: 12px;
  text-align: center,
}

.arrow_box {
  position: relative;
  border-radius: .4em;
	background: transparent;
  border: 2px solid #ff4d4f;
  padding-left: 5% !important;
  padding-right: 5% !important;
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}

.arrow_box:after { 
  content: ''; 
  position: absolute; 
  bottom: -40%;
  left: 50%; 
  width: 0; 
  height: 0; 
  border: 6px solid transparent; 
  border-top-color: #ff4d4f; 
  border-bottom: 0; 
  margin-left: -6px; 
  margin-top: -6px;
}

@-moz-document url-prefix(){
  .arrow_box{
    border: 3px solid #ff4d4f;
  }
}

.inline-div{
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}

.inline-div-center{
  -webkit-transform: translateY(-45px);
          transform: translateY(-45px);
}

.not-margin .ant-form {
  width: 100%;
}

.not-margin > div {
  margin: 0px;
  padding: 1em;
  border: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

.uv_control_schedule{
 margin-bottom: 50px;
}

.uv_control_schedule > div{
  margin-bottom: 0px;
 }

 .uv_control_schedule > div.controller-form{
  margin-top: 30px;
 }


.uv_control_schedule .controller__title.start, .uv_control_schedule .controller__title.stop{
  width: 128px;
  text-align: center;
}

.uv_control_schedule .controller-form__controller{
  line-height: 0px;
}

.line-bottom-div{
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

.margin-right-20{
  margin-right: 20px !important;
}
                      

.uv_control_schedule .controller__remove-btn .anticon {
  margin: 6px;
}
.ant-descriptions {
  max-width: 600px;
}

.device{
    margin-left: -20px;
    margin-top: -10px;
  }

  .contenedor{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}
/* i.ant-dropdown-menu-submenu-arrow-icon > svg {
  color: #fff !important;
} */

.current-search > .ant-tree-node-content-wrapper {
  color: #FFF;
  border: 1px solid #c9e3fa;
  /* Firefox */
  -moz-transition-property: all;
  -moz-transition-duration: 1s;
  -moz-transition-timing-function: ease;
  -moz-transition-delay: 0.2s;
  /* Chrome / Safari */
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: ease;
  -webkit-transition-delay: 0.2s;
  /* Opera */
  -o-transition-property: all;
  -o-transition-duration: 1s;
  -o-transition-timing-function: ease;
  -o-transition-delay: 0.2s;
  /* Default */
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease;
  transition-delay: 0.2s;
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 1; }

@-webkit-keyframes parpadeo {
  0% {
    box-shadow: 0 0 20px #c9e3fa; }
  100% {
    box-shadow: 0 0 0px #c9e3fa; } }

@keyframes parpadeo {
  0% {
    box-shadow: 0 0 20px #c9e3fa; }
  100% {
    box-shadow: 0 0 0px #c9e3fa; } }

.is-drag.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: transparent !important; }

.ant-tree li.selected-key > .ant-tree-node-content-wrapper {
  background-color: #b2c1c2 !important; }

.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 13px; }

.tree-box {
  width: 100%;
  height: 100%;
  padding: 2em;
  border: 1px solid var(--element-color-hover);
  border-radius: var(--element-border-radius);
}

.auto-selected > .ant-tree-node-content-wrapper {
  background-color: #b2c1c2;
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 5px;
}

.input-form {
  width: 80%;
}

.ant-form {
  width: 80%;
}

.ant-form {
  width: 80%;
}

.ant-form {
  width: 80%;
}

.ant-form {
  width: 100%; }

.controller-form {
  margin: 0.2% 0;
  padding: 0.5% 0; }
  .controller-form__controller {
    display: block;
    block-size: 100%;
    grid-template-columns: 100% 20%;
    grid-template-rows: 100%; }
    .controller-form__controller .controller {
      margin-right: 10%;
      display: inline-flex; }
      .controller-form__controller .controller__title {
        margin: 1%; }
      .controller-form__controller .controller__remove-btn {
        margin-left: 5%;
        margin-top: 2%;
        display: inline-flex;
        justify-content: center;
        cursor: pointer; }

.ant-form {
  width: 80%;
}

.ant-form {
  width: 80%;
}

.span-occupancy-limit{
  margin-bottom: 30px;
  display: block;
  font-size: 12px;
}
.ant-form {
  width: 80%;
}

.input-tree-search .anticon-search {
  font-size: 14px !important;
  padding-top: 2px; }

.input-tree-search input {
  padding-right: 10px !important; }

.input-tree-search.buttons-search input {
  padding-right: 80px !important; }

.input-tree-search .ant-input-prefix, .input-tree-search .ant-input-prefix .icon-animated, .input-tree-search .ant-input-prefix .icon-animated .anticon[tabindex] {
  cursor: default; }

.ant-descriptions {
  max-width: 600px;
}

.contenedor > div > .panel{
  padding-bottom: 30px;
  overflow: hidden;
}

.title-panel {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 0px !important;
}

.move-title-panel {
  margin-right: 60px;
  height: 100%;
  display: block;
}

.title-panel > .move-title-panel > .text-title-panel {
  padding: 5px;
  display: block;
  width: 100%;
}

.title-panel > .btn-panel.close {
  margin: 5px;
}

.title-panel > .btn-panel.maximizer {
  margin: 5px 0px;
}

.body-panel {
  width: 100%;
  margin-right: 5px;
  height: 100%;
  margin-top: 30px;
  z-index: 0;
  margin-bottom: -30px;
}

.contenedor > div > .panel *::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: rgba(40, 45, 49, 0.07);
  border-radius: 50px;
}

/* i.ant-dropdown-menu-submenu-arrow-icon > svg {
  color: #fff !important;
} */

.board, .full-height, .full-height > section.ant-layout, .full-height > section.ant-layout > main,
.full-height .board .ant-card-body, .full-height .board .ant-card-body .board-inner {
  height: 100%;
}

.full-height .board .ant-card-body{
  padding: 0px;
  padding-bottom: 60px;
}

.panel{
  border: 1px solid #eee;
  background-color: #fff;
}

.title-panel{
  background-color: #eee;
  padding: 5px;
  height: 30px;
}

.text-title-panel{
  width: -webkit-fill-available;
  width: -moz-available;
  left: 0;
}

.title-panel .btn-panel{
  float: right;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0px 2px;
}

.title-panel .btn-panel.close{
  background-color: #f66;
  color: #fff;
}
.title-panel .btn-panel.maximizer{
  background-color: #35a0b5;
  color: #fff;
}

.full-height .board .ant-card-body > .contenedor{
    position: relative;
    height: 100%;
    width: 100%;
}

.full-height .ant-col-6 > .ant-card.ant-card-bordered >  .ant-card-body{
padding: 0px;
}

.ant-row-flex {
  margin: 0 2em;
}

.ant-form {
  /*width: 80%;*/
}

/*.ant-form {
  width: 80%;
}*/

.select-container.ant-form-item-control-wrapper {
  width: 100%;
}

.ant-row-flex {
  margin: 0 2em;
}

.ant-form {
  /*width: 80%;*/
}

.centered-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
}

.centered-subtitle {
  text-align: center;
  margin-bottom: 2.5em;
}

.centered-img {
  object-fit: cover;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  width: 100% !important;
}

.centered-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
}

.centered-subtitle {
  text-align: center;
  margin-bottom: 2.5em;
}

.centered-img {
  object-fit: cover;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  width: 100% !important;
}

