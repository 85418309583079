.board, .full-height, .full-height > section.ant-layout, .full-height > section.ant-layout > main,
.full-height .board .ant-card-body, .full-height .board .ant-card-body .board-inner {
  height: 100%;
}

.full-height .board .ant-card-body{
  padding: 0px;
  padding-bottom: 60px;
}

.panel{
  border: 1px solid #eee;
  background-color: #fff;
}

.title-panel{
  background-color: #eee;
  padding: 5px;
  height: 30px;
}

.text-title-panel{
  width: -webkit-fill-available;
  width: -moz-available;
  left: 0;
}

.title-panel .btn-panel{
  float: right;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0px 2px;
}

.title-panel .btn-panel.close{
  background-color: #f66;
  color: #fff;
}
.title-panel .btn-panel.maximizer{
  background-color: #35a0b5;
  color: #fff;
}

.full-height .board .ant-card-body > .contenedor{
    position: relative;
    height: 100%;
    width: 100%;
}

.full-height .ant-col-6 > .ant-card.ant-card-bordered >  .ant-card-body{
padding: 0px;
}
