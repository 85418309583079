.current-search > .ant-tree-node-content-wrapper{
    //border: 1px solid red;


    color: #FFF;
    //background: #e4eef7;
    border: 1px solid #c9e3fa;
    //box-shadow: 0 0 20px gold;
 
    /* Firefox */
    -moz-transition-property: all;
    -moz-transition-duration: 1s;
    -moz-transition-timing-function: ease;
    -moz-transition-delay: 0.2s;
 
 
    /* Chrome / Safari */
    -webkit-transition-property: all;
    -webkit-transition-duration: 1s;
    -webkit-transition-timing-function: ease;
    -webkit-transition-delay: 0.2s;
 
 
    /* Opera */
    -o-transition-property: all;
    -o-transition-duration: 1s;
    -o-transition-timing-function: ease;
    -o-transition-delay: 0.2s;
 
 
    /* Default */
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0.2s;
    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  
    -webkit-animation-name:parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
  }
  
  @-moz-keyframes parpadeo{  
    0% { box-shadow: 0 0 20px #c9e3fa; }
    100% { box-shadow: 0 0 0px #c9e3fa; }
  }
  
  @-webkit-keyframes parpadeo {  
    0% { box-shadow: 0 0 20px #c9e3fa; }
    100% { box-shadow: 0 0 0px #c9e3fa; }
  }
  
  @keyframes parpadeo {  
    0% { box-shadow: 0 0 20px #c9e3fa; }
    100% { box-shadow: 0 0 0px #c9e3fa; }
  }


.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected{
  
}

.is-drag.ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: transparent !important;
}

.ant-tree li.selected-key > .ant-tree-node-content-wrapper {
  background-color: #b2c1c2 !important;
}

.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 13px;
}