.device{
    width: 40px;
    margin-left: -20px;
    margin-top: -10px;
  }

  .device.inactive::after{
    content: '';
    border-radius: 100px;
    width: 100px;
    height: 100px;
    margin-top: -80px;
    margin-left: -30px;
    display: inherit;
    background: -webkit-radial-gradient( 50% 50%, circle farthest-side, rgb(36, 36, 36), rgba(36, 36, 36, 0) );
	  background: radial-gradient( farthest-side circle at 50% 50%, rgb(36, 36, 36), rgba(36, 36, 36, 0) );
  }

  .contenedor{
    width: fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}


@supports (-ms-ime-align:auto) {
  .contenedor{
    display: table;
  }
}
@media screen and (min-width:0\0){
  .contenedor{
    display: table;
  }
 }

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}

.legend .ant-tooltip-inner{
  width: 500px;
  color: black;
  background-color: rgb(255, 255, 255);
}

.device-name{
  font-size: 12px;
  margin-top: -5px;
  white-space: nowrap;
  color: black;
  font-weight: 600;
  text-shadow: -1px -1px 3px #ffffff, 1px 1px 3px #ffffff;
  display: inline-grid;
  margin-left: -30%;
  line-height: 12px;
  min-width: 40px;
}

.device-name > span:nth-child(2){
  font-size: 10px;
  line-height: 10px;
}

.exit-name.device-name{
  text-transform: uppercase;
  margin-left: 0%;
}

.exit.device{
line-height: 14px;
}


.image-name.text-green{
  color: green;
}
.image-name.text-red{
  color: red;
}
.image-name{
  font-size: 12px;
  white-space: nowrap;
  color: black;
  font-weight: 700;
  text-shadow: -1px -1px 3px #ffffff, 1px 1px 3px #ffffff;
}


.green-button, .green-button.ant-btn:hover, .green-button.ant-btn:focus, .green-button > button{
  background-position: center;
    background-size: cover;
  background: url('/green.png');
}

.tricolor-button, .tricolor-button.ant-btn:hover, .tricolor-button.ant-btn:focus, .tricolor-button > button{
  background-position: center;
    background-size: cover;
  background: url('/tricolor.png');
}

.arrows-button, .arrows-button.ant-btn:hover, .arrows-button.ant-btn:focus, .arrows-button > button{
  background-position: center;
    background-size: cover;
  background: url('/arrows.png');
}

.ant-list.occupancy-data{
  margin-bottom: 10px;
}
.ant-list.occupancy-data .ant-list-header, .compilance-warning .ant-list-header{
  text-align: center;
  padding: 5px 5px;
  font-weight: 600;
}

.ant-list.occupancy-data ul.ant-list-items li{
  text-align: center;
  padding: 5px 14px;
}

.ant-list.occupancy-data .occupancy-data-value{
  color: #287d8e;
  font-size: 16px;
  font-weight: 700;
}

.ant-list.compilance-warning ul.ant-list-items li{
  padding: 5px 2px;
}

.ant-list.compilance-warning li .warning-time{
  font-size: 11px;
  width: 35px;
  position: absolute;
  display: inline-block;
  margin-top: 2px;
}

.ant-list.compilance-warning li .warning-text{
  font-size: 13px;
  margin-left: 38px;
}

.red-text{
  color:rgb(206, 17, 38) !important;
}

.motion-buttons > .ant-btn{
  margin-right: 1px;
  margin-bottom: 1px;
}

.motion-buttons > .ant-btn.selected{
  border-color: #287d8e;
}

.frecuencycontrol .ant-btn-circle{
  width: 32px;
}