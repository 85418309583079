.centered-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
}

.centered-subtitle {
  text-align: center;
  margin-bottom: 2.5em;
}

.centered-img {
  object-fit: cover;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  width: 100% !important;
}
