.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #287d8e;
  border-radius: 50%;
}

.circle.animar {
  animation: anim 1.8s linear infinite;
}

.circle.animar:nth-child(2) {
  animation-delay: 0.15s;
}

.circle.animar:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes anim {
  0% {
    transform-origin: 200% 30%;
    transform: rotate(0);
  }
  50% {
    transform-origin: 200% 30%;
    transform: rotate(360deg);
  }
  50.1% {
    transform-origin: -100% 30%;
    transform: rotate(360);
  }
  100% {
    transform-origin: -100% 30%;
    transform: rotate(0);
  }
}
