.ant-form {
  width: 80%;
}

.ant-radio-button-wrapper{
  width: 70px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
}

.ant-radio-button-wrapper.middle{
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.advanced-schedule .ant-form-item-control{
  line-height: 1.5;
}

.advanced-schedule .ant-checkbox-group > label{
  width: 100%;
  margin-top: 5px;
}

.advanced-schedule .advanced-schedule-tab > .ant-tabs-content > div{
  padding: 0px 40px;
}
.advanced-schedule .advanced-schedule-tab .schedule-form{
  margin-top: 20px;
}

.slider-select-period{
  padding: 0px 40px;
}