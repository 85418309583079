.frecuencycontrol .slider-hour .ant-slider-rail, .ant-slider-track {
    position: absolute;
    height: 4px !important;
    background-color: #aaa !important; 
}

.frecuencycontrol .slider-hour .ant-slider-step .ant-slider-dot{
    border-color: #287d8e !important;
    background-color:  #287d8e !important;
}
.frecuencycontrol .slider-hour .step1 .ant-slider-step .ant-slider-dot:nth-child(1),
.frecuencycontrol .slider-hour .step2 .ant-slider-step .ant-slider-dot:nth-child(2),
.frecuencycontrol .slider-hour .step3 .ant-slider-step .ant-slider-dot:nth-child(3),
.frecuencycontrol .slider-hour .step4 .ant-slider-step .ant-slider-dot:nth-child(4),
.frecuencycontrol .slider-hour .step5 .ant-slider-step .ant-slider-dot:nth-child(5),
.frecuencycontrol .slider-hour .step6 .ant-slider-step .ant-slider-dot:nth-child(6),
.frecuencycontrol .slider-hour .step7 .ant-slider-step .ant-slider-dot:nth-child(7),
.frecuencycontrol .slider-hour .step8 .ant-slider-step .ant-slider-dot:nth-child(8),
.frecuencycontrol .slider-hour .step9 .ant-slider-step .ant-slider-dot:nth-child(9),
.frecuencycontrol .slider-hour .step10 .ant-slider-step .ant-slider-dot:nth-child(10),
.frecuencycontrol .slider-hour .step11 .ant-slider-step .ant-slider-dot:nth-child(11),
.frecuencycontrol .slider-hour .step12 .ant-slider-step .ant-slider-dot:nth-child(12),
.frecuencycontrol .slider-hour .step13 .ant-slider-step .ant-slider-dot:nth-child(13),
.frecuencycontrol .slider-hour .step14 .ant-slider-step .ant-slider-dot:nth-child(14),
.frecuencycontrol .slider-hour .step15 .ant-slider-step .ant-slider-dot:nth-child(15),
.frecuencycontrol .slider-hour .step16 .ant-slider-step .ant-slider-dot:nth-child(16),
.frecuencycontrol .slider-hour .step17 .ant-slider-step .ant-slider-dot:nth-child(17),
.frecuencycontrol .slider-hour .step18 .ant-slider-step .ant-slider-dot:nth-child(18),
.frecuencycontrol .slider-hour .step19 .ant-slider-step .ant-slider-dot:nth-child(19),
.frecuencycontrol .slider-hour .step20 .ant-slider-step .ant-slider-dot:nth-child(20),
.frecuencycontrol .slider-hour .step21 .ant-slider-step .ant-slider-dot:nth-child(21),
.frecuencycontrol .slider-hour .step22 .ant-slider-step .ant-slider-dot:nth-child(22),
.frecuencycontrol .slider-hour .step23 .ant-slider-step .ant-slider-dot:nth-child(23),
.frecuencycontrol .slider-hour .step24 .ant-slider-step .ant-slider-dot:nth-child(24){
    border-color: #ff6060  !important;
    background-color:  #ff6060 !important;
}

.frecuencycontrol .slider-hour .ant-slider-mark-text {
    color: 
rgb(40, 45, 49) !important;
}