.device{
    margin-left: -20px;
    margin-top: -10px;
  }

  .contenedor{
    width: fit-content;
    height: fit-content;
    text-align: center;
    margin: auto;
    overflow: hidden;
}

.horizontal{
  width: 500px;
  height: auto;
}

.vertical{
  height: 500px;
  width: auto;
}