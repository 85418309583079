/* :root {
  --primary-color-bg: #595959;
  --card-hoverable-color: #5959597c;
  --red-color: #da2e4f;
  --red-color-hover: #da2e50ce;
  --primary-color: #6e5c9a;
  --primary-color-hover: #6e5c9acb;
  --badge-border-color: #6e5c9a;
  --badge-border-coslor: #6e5c9a7c;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.primary__color {
  cursor: pointer;
  transition: background color border tranform 0.3s 0.3s 0.3s 0.1s;
}

.primary__color:hover {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

/** LogIn Page 
.form-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 1.5em;
}

/** Evaluator Page 
.sider-logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.header-styles {
  background-color: #fff;
  box-shadow: 0 0 10px var(--card-hoverable-color);
  z-index: 1;
  padding-left: 1em;
}

.sider-styles {
  box-shadow: 0 0 10px var(--card-hoverable-color);
  border-right-width: 0;
  z-index: 1;
}

.content-styles {
  
  background-color: #fff;
}

.content-layout-styles {
  
}

.navbar-col {
}

.btn-link {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
}

.btn-link:hover {
  text-decoration: underline;
}

.notification-title {
  display: flex;
  align-items: center;
  justify-content: start;
}

.ant-layout-sider-trigger:hover .anticon-right,
.ant-layout-sider-trigger:hover .anticon-left {
  color: var(--primary-color);
}
.ant-layout-sider-trigger:active .anticon-right,
.ant-layout-sider-trigger:active .anticon-left,
.primary__color:active {
  transform: scale(0.9);
}

.ant-menu-vertical {
  border-right: 0;
}

.card__click:active {
  transform: scale(0.99);
}

.ant-list-item-meta {
  align-items: center;
}

.ant-pagination {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
}

.ant-card {
  overflow: hidden;
}

.ant-card-actions {
  transform: translateY(298px);
  transition: all 0.3s;
}

.ant-card:hover .ant-card-actions {
  transform: translateY(0);
}

.badge-hoverable {
  transition: all 0.3s;
}

.card-hoverable:hover,
.badge-hoverable:hover {
  box-shadow: 0 4px 12px var(--card-hoverable-color);
  cursor: pointer;
  transform: translateY(-8px);
  padding-bottom: 0;
}

.badge-hoverable:hover .ant-card {
  margin-bottom: 0;
}

.card-hoverable:hover .ant-avatar {
  background-color: var(--primary-color-hover);
}

.ant-card-grid {
  height: 15em;
  overflow: hidden;
}

.row-flex-padding {
  padding: 3em;
}

.flex-margin {
  margin: 3em;
}

.card__active {
  box-shadow: 0 0 3px var(--primary-color);
}
.card__active .ant-avatar {
  background-color: var(--primary-color);
}

.footer {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.editable_row {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 90%;
}

.editable {
  display: inline-block;
  border-bottom: 1px solid transparent;
  cursor: text;
  padding-right: 1vw;
  min-width: 5vw;
  min-height: 20px;
  margin-bottom: 0;

  transition: all 0.3s;
}

.editable:hover {
  border-bottom: 1px solid var(--primary-color);
}

.editing {
  border-bottom: 1px solid var(--primary-color);
}

.editing:focus {
  outline: none;
}

.ant-btn-danger {
  color: #fff;
  background-color: var(--red-color);
  border: none;
}

.ant-btn-danger:hover {
  color: #fff;
  background-color: var(--red-color-hover);
  border: none;
}

.ant-badge-count {
  background-color: var(--red-color);
}

.box-col {
  padding: 1em;
  box-shadow: 0 0 10px #d9d9d9;
}

.ant-table-row:hover .ant-avatar {
  background-color: var(--primary-color-hover);
}

.ant-avatar {
  transition: 0.3s all;
}

.ant-form-item-control {
  line-height: 1.5;
}

/** SingUp Page 
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.back-login {
  position: absolute;
  top: 8vh;
  font-size: 16px;
}
.back-login svg {
  transition: transform 0.1s ease-in;
}
.back-login:hover svg {
  transform: translateX(-0.3em);
}

.steps-action {
  text-align: center;
}
.steps-content {
  padding-top: 0;
  text-align: left;
} 
*/

:root {
  --primary-color: #287d8e;
  --primary-color-hover: #1d5a66;
  --element-color-hover: #5959590e;
  --element-border-radius: 4px;
  /* --dark-icon-color: #595959; 
     --card-hoverable-color: #5959597c; */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.ant-avatar {
  background-color: var(--primary-color);
}

/*? Estilos al scroll del navegador cuando es chrome */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  z-index: 10;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-hover);
}
*::-webkit-scrollbar-thumb:active {
  background: var(--primary-color-hover);
}

/*? Estilos al scroll cuando es firefox */
/* body {
  overflow-y: scroll;
  scrollbar-color: var(--primary-color) transparent;
  scrollbar-width: thin;
} */
