.ant-form {
  width: 80%;
}

.ant-radio-button-wrapper{
  width: 70px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
}

.ant-radio-button-wrapper.middle{
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
  color: #287e8f;
  background: #fff;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled).radio-button-overriden {
  color: #ff4d4f;
  border-color: #fab9ba;
  background: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  z-index: 1;
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden{
  box-shadow: -1px 0 0 0 #ff4d4f;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).radio-button-overriden::before {
  background-color: #ff4d4f !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  z-index: 1;
  color: #fff;
  background: #287e8f;
  border-color: #287e8f;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:hover:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
}

.bubble-text {
  color: #ff4d4f;
  font-size: 12px;
  text-align: center,
}

.arrow_box {
  position: relative;
  border-radius: .4em;
	background: transparent;
  border: 2px solid #ff4d4f;
  padding-left: 5% !important;
  padding-right: 5% !important;
  transform: translateY(-60px);
}

.arrow_box:after { 
  content: ''; 
  position: absolute; 
  bottom: -40%;
  left: 50%; 
  width: 0; 
  height: 0; 
  border: 6px solid transparent; 
  border-top-color: #ff4d4f; 
  border-bottom: 0; 
  margin-left: -6px; 
  margin-top: -6px;
}

@-moz-document url-prefix(){
  .arrow_box{
    border: 3px solid #ff4d4f;
  }
}

.inline-div{
  transform: translateY(-60px);
}

.inline-div-center{
  transform: translateY(-45px);
}

.not-margin .ant-form {
  width: 100%;
}

.not-margin > div {
  margin: 0px;
  padding: 1em;
  border: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

.uv_control_schedule{
 margin-bottom: 50px;
}

.uv_control_schedule > div{
  margin-bottom: 0px;
 }

 .uv_control_schedule > div.controller-form{
  margin-top: 30px;
 }


.uv_control_schedule .controller__title.start, .uv_control_schedule .controller__title.stop{
  width: 128px;
  text-align: center;
}

.uv_control_schedule .controller-form__controller{
  line-height: 0px;
}

.line-bottom-div{
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

.margin-right-20{
  margin-right: 20px !important;
}
                      

.uv_control_schedule .controller__remove-btn .anticon {
  margin: 6px;
}