.ant-form {
  width: 80%;
}


.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.toasts-message ul.ant-list-items > li{
  padding: 2px 0;
}

.toasts-message ul.ant-list-items > li > div{
  padding: 5px 15px 5px 64px;
  width: 100%;
}


.toasts-message ul.ant-list-items > li > div.ant-alert-error {
  background-color: transparent;
  border-left: 5px solid #f5222d;
}

.toasts-message ul.ant-list-items > li > div.ant-alert-info {
  background-color: transparent;
  border-left: 5px solid #1890ff;
}

.toasts-message ul.ant-list-items > li > div.ant-alert-warning {
  background-color: transparent;
  border-left: 5px solid #faad14;
}

.toasts-message ul.ant-list-items > li > div.ant-alert-success {
  background-color: transparent;
  border-left: 5px solid #52c41a;
}

.toasts-message ul.ant-list-items > li i.ant-alert-icon {
  top: 6px;
}

.toast-data .toasts-message i.anticon-bell{
  font-size: 16px;
    border: 2px solid #52c41a;
    border-radius: 50px;
    padding: 3px;
}