
.input-tree-search .anticon-search{
    font-size: 14px !important;
    padding-top: 2px;
}

.input-tree-search input{
    padding-right: 10px !important;
}

.input-tree-search.buttons-search input{
    padding-right: 80px !important;
}

.input-tree-search .ant-input-prefix, .input-tree-search .ant-input-prefix .icon-animated, .input-tree-search .ant-input-prefix .icon-animated .anticon[tabindex]{
    cursor: default;
}