.ant-layout-sider {
  /*border-right: 1px solid #e8e8e8;*/
  background-color: #0E132B;
  z-index: 1;
}

.ant-menu {
  background-color: #0E132B;
}

.ant-layout-sider-trigger {
  /*border-right: 1px solid #e8e8e8;*/
}

.ant-layout-content {
  /*background-color: rgb(240, 240, 240);*/
}

.logo {
  height: 80px;
  background: url('/logo.png');
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: contain;
  background-position-x: center;
  margin: 16px;
}
