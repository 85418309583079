.tree-box {
  width: 100%;
  height: 100%;
  padding: 2em;
  border: 1px solid var(--element-color-hover);
  border-radius: var(--element-border-radius);
}

.auto-selected > .ant-tree-node-content-wrapper {
  background-color: #b2c1c2;
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 5px;
}
